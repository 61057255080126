import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Sticker
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUuNSAzSDVhMiAyIDAgMCAwLTIgMnYxNGMwIDEuMS45IDIgMiAyaDE0YTIgMiAwIDAgMCAyLTJWOC41TDE1LjUgM1oiIC8+CiAgPHBhdGggZD0iTTE0IDN2NGEyIDIgMCAwIDAgMiAyaDQiIC8+CiAgPHBhdGggZD0iTTggMTNoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNiAxM2guMDEiIC8+CiAgPHBhdGggZD0iTTEwIDE2cy44IDEgMiAxYzEuMyAwIDItMSAyLTEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/sticker
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Sticker = createLucideIcon('StickerIcon', [
  [
    'path',
    { d: 'M15.5 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V8.5L15.5 3Z', key: '1wis1t' },
  ],
  ['path', { d: 'M14 3v4a2 2 0 0 0 2 2h4', key: '36rjfy' }],
  ['path', { d: 'M8 13h.01', key: '1sbv64' }],
  ['path', { d: 'M16 13h.01', key: 'wip0gl' }],
  ['path', { d: 'M10 16s.8 1 2 1c1.3 0 2-1 2-1', key: '1vvgv3' }],
]);

export default Sticker;
