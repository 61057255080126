import createLucideIcon from '../createLucideIcon';

/**
 * @component @name GitCommitVertical
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgM3Y2IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMiIC8+CiAgPHBhdGggZD0iTTEyIDE1djYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/git-commit-vertical
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const GitCommitVertical = createLucideIcon('GitCommitVerticalIcon', [
  ['path', { d: 'M12 3v6', key: '1holv5' }],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['path', { d: 'M12 15v6', key: 'a9ows0' }],
]);

export default GitCommitVertical;
