import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Replace
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgNGEyIDIgMCAwIDEgMi0yIiAvPgogIDxwYXRoIGQ9Ik0xNiAxMGEyIDIgMCAwIDEtMi0yIiAvPgogIDxwYXRoIGQ9Ik0yMCAyYTIgMiAwIDAgMSAyIDIiIC8+CiAgPHBhdGggZD0iTTIyIDhhMiAyIDAgMCAxLTIgMiIgLz4KICA8cGF0aCBkPSJtMyA3IDMgMyAzLTMiIC8+CiAgPHBhdGggZD0iTTYgMTBWNWEzIDMgMCAwIDEgMy0zaDEiIC8+CiAgPHJlY3QgeD0iMiIgeT0iMTQiIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHJ4PSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/replace
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Replace = createLucideIcon('ReplaceIcon', [
  ['path', { d: 'M14 4a2 2 0 0 1 2-2', key: '1w2hp7' }],
  ['path', { d: 'M16 10a2 2 0 0 1-2-2', key: 'shjach' }],
  ['path', { d: 'M20 2a2 2 0 0 1 2 2', key: '188mtx' }],
  ['path', { d: 'M22 8a2 2 0 0 1-2 2', key: 'ddf4tu' }],
  ['path', { d: 'm3 7 3 3 3-3', key: 'x25e72' }],
  ['path', { d: 'M6 10V5a3 3 0 0 1 3-3h1', key: '3y3t5z' }],
  ['rect', { x: '2', y: '14', width: '8', height: '8', rx: '2', key: '4rksxw' }],
]);

export default Replace;
