import createLucideIcon from '../createLucideIcon';

/**
 * @component @name BriefcaseConveyorBelt
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMjB2MiIgLz4KICA8cGF0aCBkPSJNMTQgMjB2MiIgLz4KICA8cGF0aCBkPSJNMTggMjB2MiIgLz4KICA8cGF0aCBkPSJNMjEgMjBIMyIgLz4KICA8cGF0aCBkPSJNNiAyMHYyIiAvPgogIDxwYXRoIGQ9Ik04IDE2VjRhMiAyIDAgMCAxIDItMmg0YTIgMiAwIDAgMSAyIDJ2MTIiIC8+CiAgPHJlY3QgeD0iNCIgeT0iNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEwIiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/briefcase-conveyor-belt
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const BriefcaseConveyorBelt = createLucideIcon('BriefcaseConveyorBeltIcon', [
  ['path', { d: 'M10 20v2', key: '1n8e1g' }],
  ['path', { d: 'M14 20v2', key: '1lq872' }],
  ['path', { d: 'M18 20v2', key: '10uadw' }],
  ['path', { d: 'M21 20H3', key: 'kdqkdp' }],
  ['path', { d: 'M6 20v2', key: 'a9bc87' }],
  ['path', { d: 'M8 16V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v12', key: '17n9tx' }],
  ['rect', { x: '4', y: '6', width: '16', height: '10', rx: '2', key: '1097i5' }],
]);

export default BriefcaseConveyorBelt;
