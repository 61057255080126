import createLucideIcon from '../createLucideIcon';

/**
 * @component @name BookKey
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTkgMyAxIDEiIC8+CiAgPHBhdGggZD0ibTIwIDItNC41IDQuNSIgLz4KICA8cGF0aCBkPSJNMjAgOHYxM2ExIDEgMCAwIDEtMSAxSDYuNWExIDEgMCAwIDEgMC01SDIwIiAvPgogIDxwYXRoIGQ9Ik00IDE5LjV2LTE1QTIuNSAyLjUgMCAwIDEgNi41IDJIMTQiIC8+CiAgPGNpcmNsZSBjeD0iMTQiIGN5PSI4IiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-key
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const BookKey = createLucideIcon('BookKeyIcon', [
  ['path', { d: 'm19 3 1 1', key: 'ze14oc' }],
  ['path', { d: 'm20 2-4.5 4.5', key: '1sppr8' }],
  ['path', { d: 'M20 8v13a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20', key: '1ocbpn' }],
  ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H14', key: '1gfsgw' }],
  ['circle', { cx: '14', cy: '8', r: '2', key: 'u49eql' }],
]);

export default BookKey;
