import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Caravan
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMTlWOWE0IDQgMCAwIDAtNC00SDZhNCA0IDAgMCAwLTQgNHY4YTIgMiAwIDAgMCAyIDJoMiIgLz4KICA8cGF0aCBkPSJNMiA5aDNhMSAxIDAgMCAxIDEgMXYyYTEgMSAwIDAgMS0xIDFIMiIgLz4KICA8cGF0aCBkPSJNMjIgMTd2MWExIDEgMCAwIDEtMSAxSDEwdi05YTEgMSAwIDAgMSAxLTFoMmExIDEgMCAwIDEgMSAxdjkiIC8+CiAgPGNpcmNsZSBjeD0iOCIgY3k9IjE5IiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/caravan
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Caravan = createLucideIcon('CaravanIcon', [
  ['path', { d: 'M18 19V9a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v8a2 2 0 0 0 2 2h2', key: '19jm3t' }],
  ['path', { d: 'M2 9h3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2', key: '13hakp' }],
  ['path', { d: 'M22 17v1a1 1 0 0 1-1 1H10v-9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v9', key: '1crci8' }],
  ['circle', { cx: '8', cy: '19', r: '2', key: 't8fc5s' }],
]);

export default Caravan;
