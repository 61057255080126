import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Diff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgM3YxNCIgLz4KICA8cGF0aCBkPSJNNSAxMGgxNCIgLz4KICA8cGF0aCBkPSJNNSAyMWgxNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/diff
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Diff = createLucideIcon('DiffIcon', [
  ['path', { d: 'M12 3v14', key: '7cf3v8' }],
  ['path', { d: 'M5 10h14', key: 'elsbfy' }],
  ['path', { d: 'M5 21h14', key: '11awu3' }],
]);

export default Diff;
