import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Logs
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgMTJoOCIgLz4KICA8cGF0aCBkPSJNMTMgMThoOCIgLz4KICA8cGF0aCBkPSJNMTMgNmg4IiAvPgogIDxwYXRoIGQ9Ik0zIDEyaDEiIC8+CiAgPHBhdGggZD0iTTMgMThoMSIgLz4KICA8cGF0aCBkPSJNMyA2aDEiIC8+CiAgPHBhdGggZD0iTTggMTJoMSIgLz4KICA8cGF0aCBkPSJNOCAxOGgxIiAvPgogIDxwYXRoIGQ9Ik04IDZoMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/logs
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Logs = createLucideIcon('LogsIcon', [
  ['path', { d: 'M13 12h8', key: 'h98zly' }],
  ['path', { d: 'M13 18h8', key: 'oe0vm4' }],
  ['path', { d: 'M13 6h8', key: '15sg57' }],
  ['path', { d: 'M3 12h1', key: 'lp3yf2' }],
  ['path', { d: 'M3 18h1', key: '1eiwyy' }],
  ['path', { d: 'M3 6h1', key: 'rgxa97' }],
  ['path', { d: 'M8 12h1', key: '1con00' }],
  ['path', { d: 'M8 18h1', key: '13wk12' }],
  ['path', { d: 'M8 6h1', key: 'tn6mkg' }],
]);

export default Logs;
