import createLucideIcon from '../createLucideIcon';

/**
 * @component @name SquareArrowLeft
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjMiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Im0xMiA4LTQgNCA0IDQiIC8+CiAgPHBhdGggZD0iTTE2IDEySDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/square-arrow-left
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const SquareArrowLeft = createLucideIcon('SquareArrowLeftIcon', [
  ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' }],
  ['path', { d: 'm12 8-4 4 4 4', key: '15vm53' }],
  ['path', { d: 'M16 12H8', key: '1fr5h0' }],
]);

export default SquareArrowLeft;
