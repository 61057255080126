import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Binoculars
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTBoNCIgLz4KICA8cGF0aCBkPSJNMTkgN1Y0YTEgMSAwIDAgMC0xLTFoLTJhMSAxIDAgMCAwLTEgMXYzIiAvPgogIDxwYXRoIGQ9Ik0yMCAyMWEyIDIgMCAwIDAgMi0ydi0zLjg1MWMwLTEuMzktMi0yLjk2Mi0yLTQuODI5VjhhMSAxIDAgMCAwLTEtMWgtNGExIDEgMCAwIDAtMSAxdjExYTIgMiAwIDAgMCAyIDJ6IiAvPgogIDxwYXRoIGQ9Ik0gMjIgMTYgTCAyIDE2IiAvPgogIDxwYXRoIGQ9Ik00IDIxYTIgMiAwIDAgMS0yLTJ2LTMuODUxYzAtMS4zOSAyLTIuOTYyIDItNC44MjlWOGExIDEgMCAwIDEgMS0xaDRhMSAxIDAgMCAxIDEgMXYxMWEyIDIgMCAwIDEtMiAyeiIgLz4KICA8cGF0aCBkPSJNOSA3VjRhMSAxIDAgMCAwLTEtMUg2YTEgMSAwIDAgMC0xIDF2MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/binoculars
 * @see https://lucide.dev/guide/packages/lucide-vue - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {Component} Vue Component
 *
 */
const Binoculars = createLucideIcon('BinocularsIcon', [
  ['path', { d: 'M10 10h4', key: 'tcdvrf' }],
  ['path', { d: 'M19 7V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v3', key: '3apit1' }],
  [
    'path',
    {
      d: 'M20 21a2 2 0 0 0 2-2v-3.851c0-1.39-2-2.962-2-4.829V8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2z',
      key: 'rhpgnw',
    },
  ],
  ['path', { d: 'M 22 16 L 2 16', key: '14lkq7' }],
  [
    'path',
    {
      d: 'M4 21a2 2 0 0 1-2-2v-3.851c0-1.39 2-2.962 2-4.829V8a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v11a2 2 0 0 1-2 2z',
      key: '104b3k',
    },
  ],
  ['path', { d: 'M9 7V4a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v3', key: '14fczp' }],
]);

export default Binoculars;
